<template>
  <!--begin::Advance Table Widget 9-->
  <b-card
    title=""
    class="mb-2"
    hide-footer
    body-class="pa-0"
  >
    <!--begin::Header-->
    <b-row class="mt-8 mb-8">
      <b-col align="start" md="auto">
        <b-input-group>
          <b-form-input
            v-model="search"
            type="search"
            :placeholder="searchLabel"
          />
          <b-input-group-prepend is-text>
            <inline-svg src="/assets/svg/search.svg"/>
          </b-input-group-prepend>
        </b-input-group>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <a
          href="#"
          class="btn btn-primary font-weight-bolder font-size-sm"
          style="max-height: 38px;"
          @click="create_todo_clicked"
        >
          <i class="menu-icon flaticon2-plus" style="font-size: 1em"></i>Ny bidragsansökan
        </a>
      </b-col>
    </b-row>


    <b-row>
      <b-col cols="6" >
        <div class="bottom-alignment">
          <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ total_rows }}
        </div>

      </b-col>
      <b-col cols="6">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">

          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[100, 500, 1000]"
            v-model="per_page"
          />

          <b-pagination
            class="mb-0 mt-2 align-bottom"
            style="padding-left: 0px !important;"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            first-number
            last-number
          />
        </div>
      </b-col>
    </b-row>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body px-4 mb-8">

      <b-table
        style="width: 100%; table-layout: fixed;"
        class="table-striped"
        head-variant="light"
        hover
        :fields="todo_headers"
        :items="grants"
        :per-page="per_page"
        :current-page="current_page"
        :filter="search"
      >
        <template #cell(status)="{ item }">
          <v-chip
            style="font-size: 11px;"
            :color="get_status_color(item.status)"
            outlined
          >
            {{ statusName[item.status] }}
          </v-chip>
        </template>
        <template #cell(sum)="{ item }">
          {{ formatsek(item.sum) }} kr
        </template>
        <template #cell(from_datetime)="{ item }">
          {{ formatdate(item.from_datetime) }}
        </template>
        <template #cell(action)="{ item }">
          <div class="text-right pr-0">

            <a
              v-if="item.status !== 'REJ' && item.status !== 'DRAFT'"
              class="btn btn-icon btn-light btn-sm mx-3"
              @click="gotoGrantWizard(item.todo_id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <!--begin::Svg Icon-->
                <inline-svg src="/assets/svg/file-invoice-dollar-solid.svg"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </a>

            <a
              class="btn btn-icon btn-light btn-sm mx-3"
              @click="select_todo_clicked(item.todo_id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <!--begin::Svg Icon-->
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </a>

            <a
              v-if="item.status === 'DRAFT' || item.status === 'WAIT'"
              class="btn btn-icon btn-light btn-sm mx-3"
              @click="delete_todo_clicked(item.todo_id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <!--begin::Svg Icon-->
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </a>

          </div>
        </template>
      </b-table>

    </div>
    <!--end::Body-->
  </b-card>

</template>
<script>

import { format_sek } from '@/core/services/currency';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'grant-applications-table',
  props: ['grants'],
  emits: ['show_comments_clicked', 'publish_todo_clicked', 'select_todo_clicked', 'delete_todo_clicked', 'showChildren', 'create_todo_clicked'],
  mixins: [ toasts ],
  components: {

  },
  data() {
    return {

      activeExpanded: [],
      closedExpanded: [],
      singleExpand: false,
      headerProps: {
        sortByText: 'Sortera'
      },
      type: 'GRANT',
      expanded: {},
      list: [],
      search: '',
      title: '',
      searchLabel: '',
      rowsPerPage: [50, 100, 200, -1],
      current_page: 1,
      per_page: 100,
      total_rows: 0,
      statusOptions: [
        //{ value: 'DRAFT', text: 'Utkast' },
        //{ value: 'INVALID', text: 'Ej giltig' },
        //{ value: 'WAIT', text: 'Väntar' },
        { value: 'NOT', text: 'Komplettera' },
        { value: 'DONE', text: 'Klar' },
        //{ value: 'CN', text: 'Bekräftat' },
        //{ value: 'DEL', text: 'Raderat' },
        //{ value: 'ACC', text: 'Accepterat' },
        //{ value: 'REJ', text: 'Avslaget' },
        //{ value: 'BAC', text: 'Backlog' },
        //{ value: 'FUT', text: 'Framtida' },
        { value: 'CLOSE', text: 'Avslutat' }
      ],
      statusName: {
        DRAFT: 'Utkast',
        INVALID: 'Ej giltig',
        WAIT: 'Handläggs',
        NOT: 'Komplettera',
        DONE: 'Klar',
        CN: 'Bekräftat',
        DEL: 'Raderat',
        ACC: 'Godkänt',
        REJ: 'Nekat',
        BAC: 'Backlog',
        FUT: 'Framtida',
        CLOSE: 'Avslutat'
      },
    };
  },
  methods: {
    formatdate(dt) {
      return dayjs(dt).format('YYYY-MM-DD');
    },
    formatsek(sum) {
      return format_sek(sum);
    },
    show_comments_clicked(todo_id) {
      this.$emit('show_comments_clicked', todo_id);
    },
    gotoGrantWizard(todo_id) {
      //this.$emit('publish_todo_clicked', todo_id);
      this.$router.push(`/ml-grant-wizard/${todo_id}`);
    },
    select_todo_clicked(todo_id) {
      this.$emit('select_todo_clicked', todo_id);
    },
    delete_todo_clicked(todo_id) {
      this.$emit('delete_todo_clicked', todo_id);
    },
    showChildren(todo_id, show) {
      this.$emit('showChildren', todo_id, show);
    },
    create_todo_clicked() {
      this.$emit('create_todo_clicked');
    },
    get_status_color(status) {
      switch (status) {
        case 'DRAFT':
        case 'WAIT':
        case 'DONE':
          return 'blue';
        case 'NOT':
        case 'REJ':
          return 'red';
        case 'ACC':
        case 'CLOSE':
          return 'green';
        default:
          return 'grey';
      }
    },
  },
  watch: {
    grants(new_val) {
      this.total_rows = new_val.length;
    },
  },
  computed: {
    todo_headers() {
      return [
        { label: 'Namn på ansökan', key: 'name' },
        { label: 'Status', key: 'status' },
        { label: 'Summa', key: 'sum' },
        { label: 'Startdatum', key: 'from_datetime' },
        { label: '', key: 'action', tdClass: 'text-right' },
      ]
    },
  },
};
</script>
<style lang="scss" scoped>


  .card.card-custom > .card-body {
    padding: 0 !important;
  }
  .card.card-custom > .card-header {
    padding: 0 !important;
  }
  .checkbox > span {
    background-color: white;
    border: 1px solid #ddd;
  }
  .hplus {
    cursor: pointer;
  }
  .hminus {
    cursor: pointer;
  }


  :deep th div {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :deep .tr-orange {
    background-color: #ffb85d !important;
  }

  :deep .tr-red {
    background-color: #ffc7c7 !important;
  }

  :deep .td-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    max-width: 25%;
  }

  :deep .td-short {
    width: 32px;
  }

  :deep .td-short2 {
    width: 56px;
  }

  :deep .td-short3 {
    width: 76px;
  }

  :deep .td-short4 {
    width: 96px;
  }

  :deep .td-short5 {
    width: 116px;
  }

  :deep td {
    vertical-align: middle !important;
  }
</style>
